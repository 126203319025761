<template>
  <v-form v-model="valid" @submit.prevent="login">
    <v-card
      class="mx-3 mx-sm-auto py-3 px-6 my-8 my-sm-12"
      max-width="400"
      elevation="3"
    >
      <v-card-text>
        <v-alert v-if="message" type="error" dense color="alert">
          <small>{{ message }}</small>
        </v-alert>
        <v-row>
          <v-col>
            <v-text-field
              v-model="username"
              :rules="[(v) => !!v || 'Username is required']"
              label="Username"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="password"
              type="password"
              :rules="[(v) => !!v || 'Password is required']"
              label="Password"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-btn class="" type="login" block color="primary">
          Login
        </v-btn>
        <v-container class="d-flex justify-center">
          <small>
            Don't have an account? Register
            <router-link to="register/">here.</router-link>
          </small>
        </v-container>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<!-- TODO: give feedback, when Login credentials are wrong -->

<script>
export default {
  name: "Login",
  data: () => ({
    valid: false,
    username: "",
    password: "",
    message: undefined,
  }),
  methods: {
    login() {
      this.$store
        .dispatch("userLogin", {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          this.$router.push({ name: "Home" });
        })
        .catch((e) => (this.message = e));
    },
  },
};
</script>
